import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { storeUserInteraction } from '@shared/fetch';

export const useTrackPageViews = () => {
  const [isListening, setIsListening] = useState<boolean>(false);
  const history = useHistory();

  useEffect(() => {
    if (!isListening) {
      storeUserInteraction({ userInteractionType: 'pageView', pageName: window.location.pathname });
      setIsListening(true);
    }

    const unlisten = history.listen(({ pathname: pageName }) => {
      storeUserInteraction({ userInteractionType: 'pageView', pageName });
    });

    return () => {
      unlisten();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history]);
};
