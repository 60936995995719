import { MutableRefObject, useEffect } from 'react';

export const useOnClickOutside = (ref: MutableRefObject<HTMLElement | null>, handler: (b: boolean) => void | undefined, isOpen: boolean) => {
  useEffect(() => {
    const listener = (event: Event) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target as Node)) {
        return;
      }
      if (isOpen) {
        handler(false);
      }
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, isOpen]);
};
