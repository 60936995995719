import { authFetch } from './';
// Types
import { ISearchFilters, ISearchResponse, ITask } from '@shared/types';

export const searchWorkItems = async (clientId: number, filters: ISearchFilters): Promise<ISearchResponse> => {
  let query = `?query=${filters.query}&page=${filters.page}&pageSize=${filters.pageSize}`;
  if (filters?.state) {
    filters.state.forEach(s => {
      query += `&state=${s}`;
    });
  }
  if (filters?.type) {
    filters.type.forEach((type: string) => {
      query += `&type=${type}`;
    });
  }
  try {
    const { data } = await authFetch.get(`/api/AzureDevOps/Search/${clientId}${query}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getAdoTask = async (task: string): Promise<ITask> => {
  try {
    const { data } = await authFetch.get(`/api/LaborTracking/TimeEntries/GetADOTask?TaskId=${task}`);
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
