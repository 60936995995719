import React, { FC } from 'react';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

export const Unauthorized: FC<SvgIconProps> = props => (
  <SvgIcon {...props} viewBox='0 0 223 272'>
    <path d='M111.5,246.54c24.4-7.52,45.68-23.78,61.06-45H50.44C65.82,222.76,87.1,239,111.5,246.54Z' style={{ fill: 'none' }}></path>
    <path d='M111.12,161.53a8.88,8.88,0,1,0-6.27-2.6A8.81,8.81,0,0,0,111.12,161.53Z' style={{ fill: '#73c167' }}></path>
    <path
      d='M143.72,116.63h-7.44V103.76a25.17,25.17,0,1,0-50.33,0v12.87H78.51a8.88,8.88,0,0,0-8.86,8.87v54.33a8.89,8.89,0,0,0,8.86,8.87h65.21a8.91,8.91,0,0,0,8.87-8.87V125.5a8.89,8.89,0,0,0-8.87-8.87Zm-50.91-2V103.76a18.31,18.31,0,0,1,36.61,0v12.87H92.81Zm52.91,10.87v56.33H76.51V123.5h69.21Z'
      style={{ fill: '#73c167' }}
    ></path>
    <path
      d='M9.09,55.2v68.42C9.09,188,53,247.43,111.5,262.88,170,247.43,213.91,188,213.91,123.62V55.2L111.5,9.68Zm192.72,2,5.34,2.38v64c0,59.59-39.11,114.9-93,131.53l-2.65.81-2.65-.81c-53.89-16.63-93-71.94-93-131.53v-64l5.34-2.38,86.66-38.49,3.65-1.63,3.65,1.63Z'
      style={{ fill: '#73c167' }}
    ></path>
    <path d='M176.32,201.57v-5.43c-1.2,1.85-2.47,3.65-3.76,5.43Z' style={{ fill: '#fff' }}></path>
    <path d='M45.91,201.57h4.53q-2.36-3.25-4.53-6.65Z' style={{ fill: '#fff' }}></path>
  </SvgIcon>
);
